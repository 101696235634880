.slick-dots li.slick-active button:before {
  opacity: .75;
  color: green !important;
}
.slick-dots li button:before {
  font-family: 'slick';
  font-size: 14px !important;
  line-height: 20px !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 30px !important;
  height: 30px !important;
  content: '•';
  text-align: center;
  opacity: .25;
  color: black;
  -webkit-font-smoothing: antialiased;
}